@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.markdown p {
    font-size: 18px;
    text-align: justify;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rtf--mb {
    height: 76px !important;
    width: 76px !important;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@layer base {
    @font-face {
        font-family: "nyt-cheltenham";
        src: url("https://g1.nyt.com/fonts/family/cheltenham/cheltenham-normal-500.e6711d3a9af4e8cc6c129ba9940c081e.woff2") format("woff2"),
            url("https://g1.nyt.com/fonts/family/cheltenham/cheltenham-normal-500.0b6312fa2bdacb3a5d93904fb67221e6.woff") format("woff"),
            url("https://g1.nyt.com/fonts/family/cheltenham/cheltenham-normal-500.8a4b4c857c657c3ca62ebbcdd26d2698.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
        font-display: swap;
    }
    @font-face {
        font-family: "nyt-franklin";
        src: url("https://g1.nyt.com/fonts/family/franklin/franklin-normal-500.0f4aea3d462cdb64748629efcbbf36bc.woff2") format("woff2"),
            url("https://g1.nyt.com/fonts/family/franklin/franklin-normal-500.cb85480c30b6ca5f53f673993211036f.woff") format("woff"),
            url("https://g1.nyt.com/fonts/family/franklin/franklin-normal-500.9061b25032eb73a747c2edf738888891.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
        font-display: swap;
    }
}
